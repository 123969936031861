@import '../../../utils/utils';

.title {
  font-size: 3rem;
}

.progressOverlap {
  margin-bottom: -3px;
}

.progress {
  height: 3px;
}

.bar {
  background-color: $roovolt-orange;
  transition: width .2s ease-in-out;
}

.drop {
  border: .5rem dashed $roovolt-purple;
  border-radius: 1rem;
  background-color: $white;
  color: $roovolt-purple;
  transition: border-color .2s;
  min-height: 10rem;
}

.accept {
  border-color: $roovolt-orange;
}

.pop {
  animation: pop 1s ease-in-out 1;
}

@keyframes pop {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  to {
    transform: scale(1);
  }
}
