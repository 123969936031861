@charset "utf-8";
@import 'utils/constants';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

@font-face {
  font-family: Stratos;
  src: url(https://cdn.iwgb.org.uk/assets/stratosweb-bolditalic.woff);
}

$body-bg: $roovolt-purple;
$body-color: white;
$primary: $roovolt-orange;
$secondary: $roovolt-green;
$link-color: white;
$link-decoration: underline;
$yiq-contrasted-threshold: 165;
$font-family-base: Inter, Helvetica Neue, Verdana, sans-serif;

@import "../node_modules/bootstrap/scss/bootstrap";

.text-stratos {
  font-family: Stratos, $font-family-base;
}

.text-purple {
  color: $roovolt-purple;
}

.bg-purple {
  background-color: $roovolt-purple;
}

.font-size-stratos-body {
  font-size: 120%;
}

.transitioning {
  overflow: hidden;
}

.disabled {
  pointer-events: none;
}

[disabled] {
  cursor: not-allowed;
}
