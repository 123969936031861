@import 'src/utils/utils';

.title {
  line-height: 1;
  font-size: 4rem;

  @include media-breakpoint-up(sm) {
    font-size: 6rem;
  }
}

.subtitle {
  font-size: 1.25rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.75rem;
  }
}
