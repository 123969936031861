@import 'src/utils/utils';

.muted {
  opacity: .5;
}

.wordmark {
  height: 1.5rem;
}

.bulletLinks {
  list-style: none;

  li {
    &::after {
      content: ' · ';
    }

    &:last-of-type::after {
      content: '';
    }
  }
}
