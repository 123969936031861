.header {
  cursor: pointer;
  text-decoration: underline;
  background-color: inherit !important;
}

.card {
  background-color: inherit !important;
}

.icon {
  transition: transform .3s;
  width: 1rem;
}

.open {
  transform: rotate(90deg);
}

.message {
  padding-right: 1rem;
}
